var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "timeline__sidebar-column",
      class: {
        "timeline__sidebar-column--active": _vm.timelineHelper.isRowActive(
          _vm.column.id
        ),
      },
      on: {
        mouseenter: function ($event) {
          return _vm.timelineHelper.setActiveRow(_vm.column)
        },
        mouseleave: function ($event) {
          return _vm.timelineHelper.setActiveRow(null)
        },
      },
    },
    [
      _vm.column.widget
        ? _c(
            "ak-widget",
            _vm._g(
              {
                attrs: {
                  slot: _vm.column.widget.slot,
                  type: _vm.column.widget.type,
                  attributes: Object.assign({}, _vm.column.widget.attributes, {
                    id: _vm.column.widget.id,
                  }),
                  callbacks: _vm.column.widget.callbacks,
                  "context-type": _vm.column.widgetContextType,
                  "column-id": _vm.column.id,
                  "callback-uri": _vm.widgetCallbackUri,
                  "params-for-callback": _vm.paramsForCallback,
                },
                slot: _vm.column.widget.slot,
              },
              _vm.$listeners
            ),
            [_vm._v("\n        " + _vm._s(_vm.column.title) + "\n    ")]
          )
        : _c("span", [
            _vm._v("\n        " + _vm._s(_vm.column.title) + "\n    "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }