var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "show-performance-info" }, [
    _vm.date
      ? _c(
          "div",
          {
            staticClass: "show-performance-info__date",
            class: { "show-performance-info__date--draft": _vm.isDraft },
          },
          [_vm._v("\n        " + _vm._s(_vm.date) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showType
      ? _c(
          "div",
          {
            staticClass: "show-performance-info__muted",
            style: { color: _vm.showTypeColor },
          },
          [_vm._v("\n        " + _vm._s(_vm.showType) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.day
      ? _c("div", { staticClass: "show-performance-info__muted" }, [
          _vm._v("\n        " + _vm._s(_vm.day) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.time
      ? _c("div", { staticClass: "show-performance-info__muted" }, [
          _vm._v("\n        " + _vm._s(_vm.time) + "\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }