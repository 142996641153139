var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline__sidebar-columns" },
    [
      _vm.columns.headerColumn
        ? _c(
            "timeline-sidebar-column",
            _vm._g(
              {
                attrs: {
                  column: _vm.columns.headerColumn,
                  "widget-callback-uri": _vm.widgetCallbackUri,
                  "params-for-callback": _vm.paramsForCallback,
                  "timeline-helper": _vm.timelineHelper,
                },
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.columns.items, function (column) {
        return _c(
          "timeline-sidebar-column",
          _vm._g(
            {
              key: column.id,
              attrs: {
                column: column,
                "widget-callback-uri": _vm.widgetCallbackUri,
                "params-for-callback": _vm.paramsForCallback,
                "timeline-helper": _vm.timelineHelper,
              },
            },
            _vm.$listeners
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }