<template>
    <div
         :class="{'timeline__sidebar-column--active': timelineHelper.isRowActive(column.id)}"
         class="timeline__sidebar-column"
         @mouseenter="timelineHelper.setActiveRow(column)"
         @mouseleave="timelineHelper.setActiveRow(null)"
    >
        <ak-widget
            v-if="column.widget"
            :type="column.widget.type"
            :attributes="{...column.widget.attributes, id: column.widget.id }"
            :slot="column.widget.slot"
            :callbacks="column.widget.callbacks"
            :context-type="column.widgetContextType"
            :column-id="column.id"
            :callback-uri="widgetCallbackUri"
            :params-for-callback="paramsForCallback"
            v-on="$listeners"
        >
            {{ column.title }}
        </ak-widget>
        <span v-else>
            {{ column.title }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        column: {
            type: Object,
            required: true,
        },
        timelineHelper: {
            type: Object,
            required: true,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
    }
}
</script>