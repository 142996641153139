<template>
    <a href="#"
       class="timeline-tab ak-tabs__tab"
       :data-group-id="groupId"
       :class="{
            'timeline-tab--active': timelineHelper.isGroupActive(groupId),
            'timeline-tab--active-muted': timelineHelper.isGroupVisible(groupId),
       }"
       @click.prevent="tabClicked"
    >
        <div class="timeline-tab__title">
            {{ group.title }}
        </div>
        <ak-badge v-if="group.dataObjectCount" class="timeline-tab__badge">
            {{group.dataObjectCount}}
        </ak-badge>
    </a>
</template>

<script>
export default {
    props: {
        groupId: {
            type: String,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
        timelineHelper: {
            type: Object,
            required: true,
        }
    },
    methods: {
        /**
         * Tab clicked
         */
        tabClicked() {
            this.$router.push({ query: { group: this.groupId } });
            this.timelineHelper.setScrollToGroupId(this.groupId);
        }
    }
}
</script>