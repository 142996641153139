<template>
    <div class="timeline-group__row">
        <div
            @mouseover="setContextualMenuHover(true)"
            @mouseleave="setContextualMenuHover(false)"
        >
            <ak-popover-menu
                v-show="isRowActive"
                direction="right"
                class="timeline-group__contextual-menu"
                @change="setContextualMenuOpen"
            >
                <template v-slot:trigger="{open:open, toggleMenu:toggleMenu}">
                    <ak-button
                            class="timeline-group__contextual-trigger"
                            size="x-small"
                            :active="open"
                            colorMode="primary"
                            @click="toggleMenu"
                    >
                        <i class="ak-icon ak-icon--large ak-icon--bolt">
                            more_horiz
                        </i>
                    </ak-button>
                </template>
                <template v-slot:default>
                    <ak-widget
                            v-for="widget in row.widgets.list"
                            :key="widget.id"
                            :attributes="{...widget.attributes, id: widget.id, showArrow: false}"
                            :callbacks="widget.callbacks"
                            :slot="widget.slot"
                            :context-type="row.widgets.contextType"
                            :object-id="row.id"
                            :callback-uri="widgetCallbackUri"
                            :params-for-callback="paramsForCallback"
                            type="ak-popover-menu-item"
                            v-on="$listeners"
                            class="ma-2"
                    />
                </template>
            </ak-popover-menu>
        </div>
        <div class="timeline-group__row-main"
             :class="{
                'timeline-group__row-main--active': isRowActive,
                'timeline-group__row-main--dragging': row.dragging,
                'timeline-group__row-main--draft': row.isDraft,
            }"
        >
            <span v-if="row.dragging && row.originalDate"
              class="timeline-group__row-drag-label"
              :class="{'timeline-group__row-drag-label--draft': row.isDraft}"
            >
                {{ $t('Slepen naar')}}
                <span class="timelinge-group__row-drag-date">
                    {{$moment(row.date).format('DD/MM/YYYY')}}
                </span>
            </span>

            <span v-if="row.isDraft" class="timeline-group__row-draft-label">
                {{ $t('Draft') }}
            </span>

            <timeline-header-column
                :column="row.headerColumn"
                :object-id="row.id"
                :row="row"
                :columns="columns"
                :timeline-helper="timelineHelper"
                :widget-callback-uri="widgetCallbackUri"
                :params-for-callback="paramsForCallback"
                :loading="loading"
                @mouseover="setRowHover(true)"
                @mouseleave="setRowHover(false)"
                @click="setRowClicked(true)"
                @clickOutside="setRowClicked(false)"
                v-on="$listeners"
            />

            <timeline-column
                v-for="(column,columnKey) in row.columns"
                :key="columnKey"
                :column="column"
                :columnKey="columnKey"
                :object-id="row.id"
                :columns="columns"
                :timeline-helper="timelineHelper"
                :widget-callback-uri="widgetCallbackUri"
                :params-for-callback="paramsForCallback"
                :loading="loading"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script>
import TimelineColumn from "./TimelineColumn.vue";
import TimelineHeaderColumn from "./TimelineHeaderColumn.vue";

export default {
    components: {
        TimelineHeaderColumn,
        TimelineColumn,
    },
    data() {
        return {
            isRowHover: false,
            isRowClicked: false,
            isContextualMenuHover: false,
            isContextualMenuOpen: false
        }
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        columns: {
            type: Object,
            required: true,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
        timelineHelper: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean
        }
    },
    computed: {
        isRowActive() {
            return this.isRowHover || this.isRowClicked || this.isContextualMenuHover || this.isContextualMenuOpen;
        }
    },
    methods: {
        /**
         * Set row hover
         * @param isActive
         */
        setRowHover(isActive) {
            this.isRowHover = isActive;
        },
        /**
         * Set row clicked
         * @param isActive
         */
        setRowClicked(isActive) {
            this.isRowClicked = isActive;
        },
        /**
         * Set is contextual menu hover
         * @param isHover
         */
        setContextualMenuHover(isHover) {
            this.isContextualMenuHover = isHover;
        },
        /**
         * Set is contextual menu hover
         * @param isOpen
         */
        setContextualMenuOpen(isOpen) {
            this.isContextualMenuOpen = isOpen;
        },
    }
}
</script>