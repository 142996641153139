<template>
    <ak-modal
        :active="true"
        @close="cancel"
    >
        <h1>{{ $t('@title verschuiven?',{'@title': 'Cast planning'}) }}</h1>
        <div>
            {{ $t('Je hebt deze planning naar @date gesleept. Klopt dat niet, dan kan je hier alsnog de juiste datum selecteren. Je kan ook ineens het tijdstip van de @title aanpassen, indien gewenst.',{'@date': dateFormatted,'@title': 'Cast planning'})}}
        </div>
        <hr class="m-b-0">
        <ak-date-time-picker
            v-model="localDate"
            id="date"
            :title="$t('Nieuwe datum en tijdstip')"
            :help-text="$t('Kies je alsnog een ander datum, dan zal deze @title naar die datum verschoven worden i. p.v. naar de datum die hier gesuggereerd wordt.',{'@title': 'Cast planning'})"
        />
        <hr class="m-t-0">
        <template v-slot:actions>
            <ak-button @click="confirm">
                {{ $t('Nu verplaatsen') }}
            </ak-button>
            <ak-button :disableBackground="true" @click="cancel">
                {{ $t('Annuleren') }}
            </ak-button>
        </template>
    </ak-modal>
</template>

<script>
export default {
    data() {
        return {
            newDate: null
        }
    },
    props: {
        show: {
            type: Boolean,
        },
        row: {
            type: Object,
        },
        date: {
            type: String,
        }
    },
    computed: {
        dateFormatted() {
            return this.$moment(this.date).format('DD/MM/YYYY');
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm', this.localDate);
        },
        cancel() {
            this.$emit('cancel');
        }
    },
    created() {
        this.localDate = this.date;
    }
}
</script>