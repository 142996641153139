<template>
    <div
        class="show-performance-info"
    >
        <div v-if="date" class="show-performance-info__date"
             :class="{'show-performance-info__date--draft': isDraft}"
        >
            {{ date }}
        </div>
        <div v-if="showType" class="show-performance-info__muted" :style="{color: showTypeColor}">
            {{ showType }}
        </div>
        <div v-if="day" class="show-performance-info__muted">
            {{ day }}
        </div>
        <div v-if="time" class="show-performance-info__muted">
            {{ time }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'show-performance-info',
    props: {
        date: {
            type: String,
        },
        day: {
            type: String,
        },
        showType: {
            type: String,
        },
        showTypeColor: {
            type: String,
        },
        time: {
            type: String,
        },
        isDraft: {
            type: Boolean,
        }
    },
    computed: {
        getDate() {

        },
        getDay() {

        },
        getTime() {

        }
    }
}
</script>
