<template>
    <div class="ak-data-wrapper">
        <div class="ak-data-table__main timeline" ref="table-main">
            <div class="timeline__top">
                <div class="ak-data-table__top">
                    <div class="ak-data-table__title-wrapper">
                        <h1 class="ak-data-table__title"> {{ title }} </h1>

                        <!-- UPDATE SPINNER -->
                        <div v-if="loading" class="ak-data-table__loading-message timeline__loading-message">
                            <ak-loader
                                    :static="true"
                                    :loading="loading"
                                    size="small"
                                    class="ak-data-table__loader"
                            />
                            {{ $t('Gegevens laden') }}
                        </div>
                        <ak-popover-menu v-if="$localeHandler.getLocales().length > 1  && hasLocalizedProperties">
                            <template v-slot:trigger="{open:open, toggleMenu:toggleMenu}">
                                <ak-button
                                        class="ak-popover-trigger"
                                        :active="open"
                                        color-mode="text"
                                        size="small"
                                        :disable-background="true"
                                        @click="toggleMenu"
                                >
                                <span class="text--upper" v-if="! compactFilters"><b class="muted">{{ $t('Toon content in het') }}</b> <strong>{{
                                        $localeHandler.getActiveLocale()
                                    }}</strong></span>
                                    <span v-else class="text--upper muted">
                                    <strong>{{$localeHandler.getActiveLocale()}}</strong>
                                </span>
                                    <i class="ak-icon m-l-5">expand_more</i>
                                </ak-button>
                            </template>
                            <template v-slot="{toggleMenu: toggleMenu}">
                                <ak-popover-menu-item
                                        v-for="locale in $localeHandler.getLocales()"
                                        :key="locale"
                                        @click="changeLocale(locale); toggleMenu();"
                                >
                                    <span class="text--upper">{{ locale }}</span>
                                </ak-popover-menu-item>
                            </template>
                        </ak-popover-menu>
                    </div>

                    <div class="ak-data-table__right">
                        <div class="ak-button-group m-r-xs-15">
                            <ak-widget
                                    v-for="widget in idleWidgets.list"
                                    :key="widget.id"
                                    :type="widget.type"
                                    :attributes="{...widget.attributes, id: widget.id}"
                                    :callbacks="widget.callbacks"
                                    :slot="widget.slot"
                                    :callback-uri="baseUri"
                                    :context-type="idleWidgets.contextType"
                                    :params-for-callback="activeParams"
                                    @new="handleNew"
                                    @export="triggerExport"
                            />
                        </div>

                        <ak-search
                            v-if="search.enabled"
                            v-model="searchString"
                            :search="search"
                            @search="dispatchSearch"
                            @searchLiteral="searchLiteral"
                        />
                    </div>
                </div>

                <!-- TITLE AND DESCRIPTION -->
                <div v-if="descr" class="col-lg-9 col-sm-8">
                    <div class="ak-data-table__text reading">
                        <p class="ak-data-table__intro" v-html="descr"></p>
                    </div>
                </div>
            </div>

            <div class="timeline__main">
                <timeline-sidebar
                    :columns="columns"
                    :timeline-helper="timelineHelper"
                    :widget-callback-uri="baseUri"
                    :params-for-callback="activeParams"
                    @export="triggerExport"
                />

                <timeline-tabs
                    v-if="groups"
                    :groups="groups"
                    :timeline-helper="timelineHelper"
                    :fetch-params="fetchParams"
                    :loading="loading"
                />

                <timeline-groups
                    v-if="dataObjectGroups"
                    :columns="columns"
                    :groups="dataObjectGroups"
                    :widget-callback-uri="baseUri"
                    :params-for-callback="activeParams"
                    :timeline-helper="timelineHelper"
                    :sorting-enabled="sortingEnabled"
                    :grouping="grouping"
                    :loading="loading"
                    ref="groups"
                    @new="handleEdit"
                    @refresh="fetchData"
                    @edit="handleEdit"
                    @delete="handleDelete"
                    @sort="handleSort"
                    @export="triggerExport"
                    @loadNext="loadNext"
                    @loadPrev="loadPrev"
                    @loadGroup="loadGroup"
                    @updateTimelineGroup="updateGroups"
                />
            </div>
        </div>

        <timeline-export-dialog
            v-if="exportRequest"
            :initEndpoint="exportRequest.initEndpoint"
            @close="closeExport"
            @downloaded="closeExport"
        />

        <router-view
            name="childview"
            @updateTimelineGroup="updateGroups"
        />
    </div>
</template>

<script>
import TimelineTabs from "../../timeline/Tab/TimelineTabs.vue";
import TimelineGroups from "../../timeline/Group/TimelineGroups.vue";
import TimelineSidebar from "../../timeline/Sidebar/TimelineSidebar.vue";
import TimelineExportDialog from "../../timeline/Export/TimelineExportDialog.vue";
import TimelineHelper from "../../timeline/Helper/TimelineHelper";

export default {
    components: {
        TimelineTabs,
        TimelineGroups,
        TimelineSidebar,
        TimelineExportDialog,
    },
    data() {
        return {
            title: null,
            descr: null,
            hasLocalizedProperties: false,
            search: false,
            searchString: '',
            noResultString: null,
            noResultWidget: null,
            fetchParams: {
                q: null
            },
            loading: true,
            idleWidgets: [],
            columns: {},
            pageJumper: {},
            contextualPageJumperWidgets: [],
            groups: {},
            dataObjectGroups: {},
            timelineHelper: new TimelineHelper(),
            viewMode: null,
            sortingEnabled: false,
            grouping: null,
            exportRequest: null,
            appendGroups: false,
            prependGroups: false,
        }
    },
    computed: {
        /**
         * Get the current bundle
         * @return {*|string}
         */
        activeBundle() {
            return this.$route.params.bundle;
        },
        /**
         * Get the current timeline definition
         * @return {*|string}
         */
        activeTimelineDefinition() {
            return this.$route.params.timelineDefinition;
        },
        /**
         * Get the active object id
         * @return {string}
         */
        activeObjectId() {
            return this.$route.params.timelineObjectId
        },
        /**
         * get the curren params
         * @return {*|Dictionary<string | (string | null)[]>}
         */
        activeParams () {
            return {
                timelineObjectId: this.activeObjectId
            };
        },
        /**
         * Get baseUri
         * @return {string}
         */
        baseUri() {
            return `/${this.activeBundle}/${this.activeTimelineDefinition}/`;
        },
        /**
         * Active page
         * @return {*}
         */
        activePage() {
            const lastVisibleGroupId = this.timelineHelper.getLastVisibleGroupId();

            if ( ! lastVisibleGroupId) {
                return 1;
            }

            return this.groups[lastVisibleGroupId].page;
        }
    },
    watch: {
        /**
         * Fetch params
         */
        activeParams: {
            deep: true,
            handler(newValue, oldValue) {
                if (! this.$deepEqual(newValue, oldValue)) {
                    this.$debounce(this.fetchData)();
                }
            }
        }
    },
    methods: {
        /**
         * Refresh
         *
         * @return {Promise<void>}
         */
        async fetchData(requestPage, disableLoader = true) {
            this.setLoading(true);
            const page = requestPage ? requestPage : this.activePage;
            const data = await this.$get(this.baseUri, {...this.fetchParams,...this.activeParams, page});
            this.handleData(data);

            if (disableLoader) {
                this.setLoading(false);
            }
        },
        /**
         * Handle data
         * @param data
         */
        handleData(data) {
            this.search = data.search;
            this.title = data.title;
            this.descr = data.descr;
            this.searchEnabled = data.search.enabled;
            this.idleWidgets = data.widgets.idle;
            this.contextualPageJumperWidgets = data.widgets.contextualPageJumper;
            this.pageJumper = data.pageJumper;
            this.noResultString = data.empty.message;
            this.noResultWidget = data.empty.widget;
            this.sortingEnabled = data.canSortNow;
            if (this.noResultWidget) {
                this.noResultWidget.widgetContextType = data.empty.contextType;
            }
            this.hasLocalizedProperties = data.hasLocalizedProperties;
            this.grouping = data.grouping;
            this.columns = data.columns;
            this.groups = this.fetchParams.q ?
                    this.getGroupsWidthObjects(data.groups):
                    data.groups;

            if (this.appendGroups) {
                this.appendGroups = false;
                this.dataObjectGroups = {...this.dataObjectGroups, ...data.dataObjectGroups};
            } else if(this.prependGroups) {
                this.prependGroups = false;
                this.dataObjectGroups = { ...data.dataObjectGroups, ...this.dataObjectGroups};
            } else {
                this.dataObjectGroups = data.dataObjectGroups;
            }
        },
        /**
         * search Literal
         * @param text
         */
        searchLiteral(text) {
            let string = this.search.query;
            string = string.replace(text, '"' + text + '"');

            this.searchString = string;
            this.dispatchSearch();
        },
        /**
         * Dispatch search
         */
        dispatchSearch() {
            this.fetchParams = {
                q: '',
            };
            this.fetchParams.q = this.searchString;
            this.fetchData();
        },
        /**
         * Set uploading
         * @param flag
         */
        setLoading(flag) {
            this.loading = flag;
        },
        /**
         * Handle new
         * @param data
         */
        handleNew(data) {
            const params = {
                bundle: data.bundleId,
                definition: data.definitionId,
                objectId: data.objectId,
            }

            if(data.gridDefinitionId) {
                params.gridDefinition = data.gridDefinitionId;
            }

            this.$router.push({
                name: 'timeline-form',
                params: params,
                query: data.query
            });
        },
        handleEdit(data) {
            const params = {
                bundle: data.bundleId,
                definition: data.definitionId,
                objectId: data.objectId,
            }

            if(data.gridDefinitionId) {
                params.gridDefinition = data.gridDefinitionId;
            }

            this.$router.push({
                name: 'timeline-form',
                params: params,
                query: data.query
            });
        },
        handleDelete(data) {
            this.fetchData();
        },
        /**
         * TriggerExport
         *
         * @param data
         */
        triggerExport(data) {
            this.exportRequest = data;
        },
        /**
         * closeExport
         */
        closeExport() {
            this.exportRequest = null;
        },
        /**
         * Handle sortincomplete with values
         * @param id
         * @param date
         */
        async handleSort({objectId, value}) {
            this.setLoading(true);

            // get the group where we have moved the data object to
            const result = await this.$post(this.baseUri + 'set-sorting', {objectId, value}, {objectId,...this.activeParams});

            this.groups = result.groups;
            this.dataObjectGroups[result.dataObjectGroup.id] = result.dataObjectGroup;

            this.setLoading(false);

        },
        /**
         * Get the groups width objects
         * @return {{}}
         */
        getGroupsWidthObjects(groups) {
            let out = {};
            Object.keys(groups).forEach(groupId => {
                if (groups[groupId].dataObjectCount) {
                    out[groupId] = groups[groupId];
                }
            });

            return out;
        },
        /**
         * Check if the groupId exists in the available groups
         * @param groupId
         * @return {boolean}
         */
        isValidGroupId(groupId) {
            return Object.keys(this.groups).includes(groupId);
        },
        /**
         * Update a group in the timeline
         *
         * @param objectId
         * @param oldObjectDate
         * @param objectDate
         * @return {Promise<void>}
         */
        async updateGroups({ groupId, objectId, oldObjectDate, objectDate}) {
            this.setLoading(true);

            const result = await this.$get(this.baseUri + 'get-groups', { objectId, oldObjectDate, objectDate, ...this.activeParams});

            this.groups = result.groups;

            // update the necesary groups
            result.dataObjectGroups.forEach( dataObjectGroup => {
                this.dataObjectGroups[dataObjectGroup.id] = dataObjectGroup;
            });

            // scroll to the group we are editing
            if (this.isValidGroupId(groupId)) {
                this.timelineHelper.setScrollToGroupId(groupId);
            }

            this.setLoading(false);
        },
        /**
         * Load the next page
         */
        async loadNext(disableLoader) {
            // stop when we have reached the amount of page or when the search is active load more is dissabled
            if (this.fetchParams.page > this.pageJumper.numberOfPages || this.fetchParams.q) {
                return;
            }

            const lastKey = Object.keys(this.dataObjectGroups).pop();
            const lastGroupPage = this.dataObjectGroups[lastKey].page;
            const page = lastGroupPage + 1;
            this.appendGroups = true;

            await this.fetchData(page, disableLoader)
        },
        /**
         * Load previous page
         * @return {Promise<void>}
         */
        async loadPrev() {
            const firstKey = Object.keys(this.dataObjectGroups)[0]
            const firstGroupPage = this.dataObjectGroups[firstKey].page;
            const oldGroupScrollWidth = this.$refs.groups.$refs.groups.scrollWidth;

            if (firstGroupPage <= 1) {
                return;
            }

            this.prependGroups = true;
            const page = firstGroupPage - 1;

            await this.fetchData(page);

            const newGroupScrollWidth = this.$refs.groups.$refs.groups.scrollWidth;
            this.$refs.groups.$refs.groups.scrollLeft = (newGroupScrollWidth - oldGroupScrollWidth) + this.$refs.groups.$refs.groups.scrollLeft;
        },
        /**
         * Load more until  group  is in view
         * @param loadGroupId
         * @return {Promise<void>}
         */
        async loadGroup(loadGroupId) {

            while (! Object.keys(this.dataObjectGroups).find(groupId => groupId === loadGroupId)) {
                await this.loadNext(false);
            }
            // to make sure we can scroll to the element we load an extra page
            await this.loadNext();

            // now try to scroll to the group again
            this.$nextTick(() => {
                this.timelineHelper.setScrollToGroupId(loadGroupId);
            });
        }
    },
    async mounted() {
        await this.fetchData();
        const groupId = this.$route.query.group;

        if (groupId) {
            this.timelineHelper.setScrollToGroupId(groupId);
        }
    }
}
</script>
