var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-popover-menu",
    _vm._g(
      {
        staticClass: "column-label-menu",
        attrs: {
          open: _vm.open,
          "trigger-label": _vm.triggerLabel,
          "trigger-icon": _vm.triggerIcon,
          direction: _vm.direction,
          "show-overflow": _vm.showOverflow,
          items: _vm.items,
          id: _vm.id,
          columnId: _vm.columnId,
          objectId: _vm.objectId,
          callbackUri: _vm.callbackUri,
          contextType: _vm.contextType,
          "params-for-callback": _vm.paramsForCallback,
        },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function (ref) {
              var open = ref.open
              var toggleMenu = ref.toggleMenu
              return [
                _c(
                  "ak-button",
                  {
                    staticClass:
                      "ak-popover-trigger column-label-menu__trigger",
                    attrs: {
                      size: "small",
                      active: open,
                      colorMode: "text",
                      disableBackground: true,
                    },
                    on: { click: toggleMenu },
                  },
                  [
                    _c("div", { staticClass: "column-label-menu__label" }, [
                      _vm.triggerIcon
                        ? _c(
                            "span",
                            { staticClass: "ak-icon ak-button__icon" },
                            [_vm._v(_vm._s(_vm.triggerIcon))]
                          )
                        : _vm._e(),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t(_vm.triggerLabel)) +
                          "\n                "
                      ),
                      _c("i", { staticClass: "ak-icon m-l-auto" }, [
                        _vm._v("expand_more"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.triggerLabelDescription
                      ? _c(
                          "div",
                          {
                            staticClass: "column-label-menu__label-description",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.triggerLabelDescription) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }