<template>
    <div
        class="status-label"
        :style="{
            '--status-label-background': this.background ? this.background.hexCode : null,
            '--status-label-color': this.color ? this.color.hexCode : null,
        }"
        :class="{
            'status-label--large': size === 'large'
        }"
    >
        <div class="column-width-comments__tooltip">
            <ak-tooltip v-if="comments" :body="comments">
                <div class="column-width-comments__tooltip-trigger"></div>
            </ak-tooltip>
        </div>
        {{ label }}
        <slot v-if="! label">
        </slot>
    </div>
</template>

<script>

export default {
    name: 'status-label',
    props: {
        background: {
            type: Object,
        },
        color: {
            type: Object,
        },
        size: {
            type: String,
        },
        comments: {
            type: String,
        },
        label: {
            type: String,
        }
    },
}
</script>
