<template>
    <ak-form-decorator
            :id="id"
            :title="title"
            :helpText="helpText"
            :error="error"
            :hasError="hasError"
            :required="required"
            :value="value"
            :locales="locales"
    >
        <v-select
                v-model="currentValue"
                :options="selectOptions"
                :multiple="false"
                :disabled="readOnly"
                :reduce="reduce"
                :class="{'ak-form__control--has-value': currentValue !== null}"
                v-on="$currentListeners"
                @option:selected="$emit('blur')"
        >
            <template #selected-option="option">
                <span style="width: 15px; height: 15px; border-radius: 50%; margin-right: 5px;" :style="{background: option.value}"></span>
                <span v-if="option" class="ak-select__option" v-html="typeof option.label === 'object' ? option.label.label : option.label"></span>
            </template>

            <template #option="option">
                <div class="d-flex align-items-center">
                    <span style="width: 15px; height: 15px; display: inline-block; border-radius: 50%; margin-right: 5px;" :style="{background: option.value}"></span>
                    <span v-html="option.label"></span>
                </div>
            </template>
        </v-select>
    </ak-form-decorator>

</template>

<script>
import FormElementMixin from "../../../../../public/spine/resources/spine-appkit-vue/src/components/form/mixins/FormElementMixin";

export default {
    name: 'color-select',
    props: {
        colorOptions: {
            type: Object,
            default: () => {return {}}
        }
    },
    mixins: [FormElementMixin],
    computed: {
        selectOptions() {
            return Object.entries(this.colorOptions).map(([color, label]) => {
                return {
                    value: color,
                    label: label
                }
            })
        }
    },
    methods: {
        reduce(option) {
            return option.value;
        },
    }
}
</script>
