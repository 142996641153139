/**
 * This class describes which fields has triggerd an action
 * e.g. validation of a matrix field
 */
export default class FormTrigger {

    /**
     * @param trigger
     */
    constructor(trigger) {
        this.trigger = trigger
    }

    /**
     *
     * @returns {*}
     */
    getTrigger() {
        return {widgetId: this.trigger};
    }
}