<template>
    <ak-modal
        v-model="show"
        width="600px"
        class="ak-export-dialog"
    >
        <h1 v-text="title ? title : $t('Export')"></h1>

        <ak-loader v-if="isLoading" :loading="isLoading" :static="true" size="small"/>
        <div v-if="errorMessage" class="ak-form__error"><i class="ak-icon m-r-xs-5">warning</i>{{errorMessage}}</div>
        <ak-widget
            v-for="(filterWidget, key) in filterWidgets"
            :key="key"
            :type="filterWidget.type"
            :attributes="{ ...filterWidget.attributes, ...filterWidget.formControl, id: filterWidget.id}"
            @input="setFilterValue($event, filterWidget.id)"
            @submit="doExport"
        />

        <div class="p-t-15">
            <ak-button @click="doExport">{{ $t('Start export')}}</ak-button>
        </div>
    </ak-modal>
</template>

<script>
export default {
    data() {
        return {
            show: true,
            isisLoading: true,
            title: null,
            filterWidgets: null,
            exportEndpoint: null,
            filterValues: {},
            errorMessage: null,
        }
    },
    props: {
        initEndpoint : {
            type : String,
            required : true
        },
    },
    /**
     *  Watch
     */
    watch: {
        show(value, oldValue) {
            if(! value) {
                this.$emit('close');
            }
        }
    },
    computed: {
        /**
         * Return the active fetch params
         * @return {*&{batch: number}}
         */
        activeParams() {
            const params = {};

            if (this.filterUi) {
                this.filterUi.applied.forEach((filter, i) => {
                    console.log(filter);
                    params[`expf${i}`] = filter.id;

                    filter.values.forEach((value, key) => {
                        params[`expv${i}[${key}]`] = value;
                    });
                });
            }

            return params;
        }
    },
    methods: {
        /**
         * Initialize
         *
         * @return {Promise<void>}
         */
        async initialize() {
            this.isLoading = true;
            const result = await this.$get(this.initEndpoint);

            this.title = result.title;
            this.filterWidgets = result.filterWidgets;
            this.exportEndpoint = result.exportEndpoint;

            this.filterWidgets.forEach(widget => {
                this.filterValues[widget.id] = widget.formControl.value;
            })

            this.isLoading = false;
        },
        /**
         * Trigger export
         * @return {Promise<void>}
         */
        async doExport() {
            this.isLoading = true;
            const result = await this.$get(this.exportEndpoint, this.filterValues);

            if(! result.success) {
                this.errorMessage = result.errorMessage;
                this.filterWidgets = result.filterWidgets;
                this.isLoading = false;
                return;
            }

            this.$actionHandler.handle(result.todos, this);
            this.$emit('close');

            this.isLoading = false;
        },
        /**
         * Set filter value
         * @param value
         * @param widgetId
         */
        setFilterValue(value, widgetId) {
            if (! value && this.filterValues[widgetId]) {
                delete this.filterValues[widgetId];
            }

            this.filterValues[widgetId] = value;
        }
    },
    created() {
        this.initialize();
    }
}
</script>