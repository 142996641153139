var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ak-data-wrapper" },
    [
      _c(
        "div",
        { ref: "table-main", staticClass: "ak-data-table__main timeline" },
        [
          _c("div", { staticClass: "timeline__top" }, [
            _c("div", { staticClass: "ak-data-table__top" }, [
              _c(
                "div",
                { staticClass: "ak-data-table__title-wrapper" },
                [
                  _c("h1", { staticClass: "ak-data-table__title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                  _vm._v(" "),
                  _vm.loading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "ak-data-table__loading-message timeline__loading-message",
                        },
                        [
                          _c("ak-loader", {
                            staticClass: "ak-data-table__loader",
                            attrs: {
                              static: true,
                              loading: _vm.loading,
                              size: "small",
                            },
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("Gegevens laden")) +
                              "\n                    "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$localeHandler.getLocales().length > 1 &&
                  _vm.hasLocalizedProperties
                    ? _c("ak-popover-menu", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function (ref) {
                                var open = ref.open
                                var toggleMenu = ref.toggleMenu
                                return [
                                  _c(
                                    "ak-button",
                                    {
                                      staticClass: "ak-popover-trigger",
                                      attrs: {
                                        active: open,
                                        "color-mode": "text",
                                        size: "small",
                                        "disable-background": true,
                                      },
                                      on: { click: toggleMenu },
                                    },
                                    [
                                      !_vm.compactFilters
                                        ? _c(
                                            "span",
                                            { staticClass: "text--upper" },
                                            [
                                              _c(
                                                "b",
                                                { staticClass: "muted" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Toon content in het"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$localeHandler.getActiveLocale()
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass: "text--upper muted",
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$localeHandler.getActiveLocale()
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "i",
                                        { staticClass: "ak-icon m-l-5" },
                                        [_vm._v("expand_more")]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (ref) {
                                var toggleMenu = ref.toggleMenu
                                return _vm._l(
                                  _vm.$localeHandler.getLocales(),
                                  function (locale) {
                                    return _c(
                                      "ak-popover-menu-item",
                                      {
                                        key: locale,
                                        on: {
                                          click: function ($event) {
                                            _vm.changeLocale(locale)
                                            toggleMenu()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text--upper" },
                                          [_vm._v(_vm._s(locale))]
                                        ),
                                      ]
                                    )
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          113581221
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ak-data-table__right" },
                [
                  _c(
                    "div",
                    { staticClass: "ak-button-group m-r-xs-15" },
                    _vm._l(_vm.idleWidgets.list, function (widget) {
                      return _c("ak-widget", {
                        key: widget.id,
                        attrs: {
                          slot: widget.slot,
                          type: widget.type,
                          attributes: Object.assign({}, widget.attributes, {
                            id: widget.id,
                          }),
                          callbacks: widget.callbacks,
                          "callback-uri": _vm.baseUri,
                          "context-type": _vm.idleWidgets.contextType,
                          "params-for-callback": _vm.activeParams,
                        },
                        on: { new: _vm.handleNew, export: _vm.triggerExport },
                        slot: widget.slot,
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.search.enabled
                    ? _c("ak-search", {
                        attrs: { search: _vm.search },
                        on: {
                          search: _vm.dispatchSearch,
                          searchLiteral: _vm.searchLiteral,
                        },
                        model: {
                          value: _vm.searchString,
                          callback: function ($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.descr
              ? _c("div", { staticClass: "col-lg-9 col-sm-8" }, [
                  _c("div", { staticClass: "ak-data-table__text reading" }, [
                    _c("p", {
                      staticClass: "ak-data-table__intro",
                      domProps: { innerHTML: _vm._s(_vm.descr) },
                    }),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "timeline__main" },
            [
              _c("timeline-sidebar", {
                attrs: {
                  columns: _vm.columns,
                  "timeline-helper": _vm.timelineHelper,
                  "widget-callback-uri": _vm.baseUri,
                  "params-for-callback": _vm.activeParams,
                },
                on: { export: _vm.triggerExport },
              }),
              _vm._v(" "),
              _vm.groups
                ? _c("timeline-tabs", {
                    attrs: {
                      groups: _vm.groups,
                      "timeline-helper": _vm.timelineHelper,
                      "fetch-params": _vm.fetchParams,
                      loading: _vm.loading,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dataObjectGroups
                ? _c("timeline-groups", {
                    ref: "groups",
                    attrs: {
                      columns: _vm.columns,
                      groups: _vm.dataObjectGroups,
                      "widget-callback-uri": _vm.baseUri,
                      "params-for-callback": _vm.activeParams,
                      "timeline-helper": _vm.timelineHelper,
                      "sorting-enabled": _vm.sortingEnabled,
                      grouping: _vm.grouping,
                      loading: _vm.loading,
                    },
                    on: {
                      new: _vm.handleEdit,
                      refresh: _vm.fetchData,
                      edit: _vm.handleEdit,
                      delete: _vm.handleDelete,
                      sort: _vm.handleSort,
                      export: _vm.triggerExport,
                      loadNext: _vm.loadNext,
                      loadPrev: _vm.loadPrev,
                      loadGroup: _vm.loadGroup,
                      updateTimelineGroup: _vm.updateGroups,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.exportRequest
        ? _c("timeline-export-dialog", {
            attrs: { initEndpoint: _vm.exportRequest.initEndpoint },
            on: { close: _vm.closeExport, downloaded: _vm.closeExport },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        attrs: { name: "childview" },
        on: { updateTimelineGroup: _vm.updateGroups },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }