var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline-group", attrs: { "data-group-id": _vm.groupId } },
    [
      _c("div", { staticClass: "timeline-group__header" }, [
        _c("div", { staticClass: "timeline-group__header-info" }, [
          _c("div", { staticClass: "timeline-group__header-title" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.group.title) + "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "timeline-group__header-date-range" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.group.dateRangeFormatted) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "timeline-group__header-widgets ak-button-group" },
          _vm._l(_vm.group.widgets.list, function (widget) {
            return _c(
              "ak-widget",
              _vm._g(
                {
                  key: widget.id + _vm.group.dataObjects.length,
                  attrs: {
                    slot: widget.slot,
                    type: widget.type,
                    attributes: Object.assign({}, widget.attributes, {
                      id: widget.id,
                    }),
                    callbacks: widget.callbacks,
                    "context-type": _vm.group.widgets.contextType,
                    "callback-uri": _vm.widgetCallbackUri,
                    "params-for-callback": Object.assign(
                      {},
                      _vm.paramsForCallback,
                      { groupDate: _vm.group.date }
                    ),
                  },
                  slot: widget.slot,
                },
                _vm.$listeners
              )
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "draggable",
        {
          ref: "draggable",
          staticClass: "timeline-group__rows",
          attrs: {
            disabled: !_vm.sortingEnabled,
            group: { name: "rows", pull: "clone", put: true },
            clone: _vm.cloneRow,
            move: _vm.checkMove,
            handle: ".timeline-group__column-header-content",
            "drag-class": "timeline-group__row--dragging",
          },
          on: {
            choose: _vm.onChoose,
            unchoose: _vm.onUnChoose,
            add: _vm.onAddRow,
            remove: _vm.onRemoveRow,
            end: _vm.onDragEnd,
            change: _vm.onChange,
          },
          model: {
            value: _vm.group.dataObjects,
            callback: function ($$v) {
              _vm.$set(_vm.group, "dataObjects", $$v)
            },
            expression: "group.dataObjects",
          },
        },
        _vm._l(_vm.group.dataObjects, function (row, key) {
          return _c(
            "timeline-row",
            _vm._g(
              {
                key: row.id + key,
                ref: "rows",
                refInFor: true,
                attrs: {
                  row: row,
                  columns: _vm.columns,
                  "timeline-helper": _vm.timelineHelper,
                  "widget-callback-uri": _vm.widgetCallbackUri,
                  "params-for-callback": _vm.paramsForCallback,
                  loading: _vm.loading,
                },
              },
              _vm.$listeners
            )
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.activeSortRequest
        ? _c("timeline-sort-modal", {
            attrs: {
              row: _vm.activeSortRequest.row,
              date: _vm.activeSortRequest.date,
            },
            on: {
              cancel: _vm.cancelSortRequest,
              confirm: _vm.confirmSortRequest,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }