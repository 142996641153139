var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-form-decorator",
    {
      attrs: {
        id: _vm.id,
        title: _vm.title,
        helpText: _vm.helpText,
        error: _vm.error,
        hasError: _vm.hasError,
        required: _vm.required,
        value: _vm.value,
        locales: _vm.locales,
      },
    },
    [
      _c(
        "div",
        { class: { "ak-form__fieldset": !_vm.hideFieldset } },
        _vm._l(_vm.currentControls, function (control) {
          return _c("ak-widget", {
            key: _vm.id + control.id,
            attrs: {
              slot: control.slot,
              type: control.type,
              attributes: _vm.widgetAttributes(control),
              callbacks: control.callbacks,
            },
            on: {
              input: function ($event) {
                return _vm.setCurrentValue($event, control.id)
              },
              change: function ($event) {
                return _vm.$emit("change")
              },
            },
            slot: control.slot,
            model: {
              value: _vm.currentValue[control.id],
              callback: function ($$v) {
                _vm.$set(_vm.currentValue, control.id, $$v)
              },
              expression: "currentValue[control.id]",
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }