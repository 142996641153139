<template>
    <ak-tabs class="timeline__tabs" ref="tabs">
        <timeline-tab
            v-for="(group,key) in groups"
            ref="tab"
            :key="key"
            :group-id="key"
            :group="group"
            :active="false"
            :timeline-helper="timelineHelper"
        />
    </ak-tabs>
</template>

<script>
import TimelineTab from "./TimelineTab.vue";

export default {
    components: {TimelineTab},
    props: {
        groups: {
            type: Object|Array,
            required: true,
        },
        timelineHelper: {
            type: Object,
            required: true,
        }
    },
    watch: {
        'timelineHelper.activeGroupId': function(){
            this.checkActiveGroupVisible(this.timelineHelper.activeGroupId);
        }
    },
    methods: {
        checkActiveGroupVisible(groupId) {
            const tab = this.$refs.tab.find(tab => tab.$el.dataset.groupId === groupId);

            if (! this.isVisible(tab.$el)) {
                this.$refs.tabs.$refs.tabsNavigation.scroll({
                    left: tab.$el.offsetLeft - 150,
                    behavior: "smooth",
                });
            }
        },
        isVisible($tab) {
            const tabRect = $tab.getBoundingClientRect();
            const tabsRect = this.$refs.tabs.$refs.tabsNavigation.getBoundingClientRect();
            const tabLeftX = tabRect.x - tabsRect.x;
            const tabRightX = (tabRect.x - tabsRect.x) + tabRect.width;

            return (tabLeftX >= 0 && tabLeftX <= tabsRect.width) &&
                    (tabRightX >= 0 && tabRightX <= tabsRect.width);
        },
    }
}
</script>
