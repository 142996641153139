var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-width-comments" },
    [
      _c(
        "div",
        { staticClass: "column-width-comments__tooltip" },
        [
          _vm.comments
            ? _c("ak-tooltip", { attrs: { body: _vm.comments } }, [
                _c("div", {
                  staticClass: "column-width-comments__tooltip-trigger",
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }