var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "timeline-tab ak-tabs__tab",
      class: {
        "timeline-tab--active": _vm.timelineHelper.isGroupActive(_vm.groupId),
        "timeline-tab--active-muted": _vm.timelineHelper.isGroupVisible(
          _vm.groupId
        ),
      },
      attrs: { href: "#", "data-group-id": _vm.groupId },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.tabClicked.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "timeline-tab__title" }, [
        _vm._v("\n        " + _vm._s(_vm.group.title) + "\n    "),
      ]),
      _vm._v(" "),
      _vm.group.dataObjectCount
        ? _c("ak-badge", { staticClass: "timeline-tab__badge" }, [
            _vm._v("\n        " + _vm._s(_vm.group.dataObjectCount) + "\n    "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }