<template>
    <div class="timeline__sidebar-columns">
        <timeline-sidebar-column
            v-if="columns.headerColumn"
            :column="columns.headerColumn"
            :widget-callback-uri="widgetCallbackUri"
            :params-for-callback="paramsForCallback"
            :timeline-helper="timelineHelper"
            v-on="$listeners"
        />
        <timeline-sidebar-column
            v-for="column in columns.items"
            :key="column.id"
            :column="column"
            :widget-callback-uri="widgetCallbackUri"
            :params-for-callback="paramsForCallback"
            :timeline-helper="timelineHelper"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import TimelineSidebarColumn from "./TimelineSidebarColumn.vue";
import TimelineHeaderColumn from "../Group/TimelineHeaderColumn.vue";

export default {
    components: {TimelineHeaderColumn, TimelineSidebarColumn},
    props: {
        columns: {
            type: Object,
            required: true,
        },
        timelineHelper: {
            type: Object,
            required: true,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
    }
}
</script>