<template>
    <div class="column-width-comments">
        <div class="column-width-comments__tooltip">
            <ak-tooltip v-if="comments" :body="comments">
                <div class="column-width-comments__tooltip-trigger"></div>
            </ak-tooltip>
        </div>

        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'column-width-comments',
    props: {
        comments: {
            type: String,
        },
    }
}
</script>
