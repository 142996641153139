var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-tabs",
    { ref: "tabs", staticClass: "timeline__tabs" },
    _vm._l(_vm.groups, function (group, key) {
      return _c("timeline-tab", {
        key: key,
        ref: "tab",
        refInFor: true,
        attrs: {
          "group-id": key,
          group: group,
          active: false,
          "timeline-helper": _vm.timelineHelper,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }