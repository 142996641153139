<template>
    <div class="timeline-group__column"
        :class="{
            'timeline-group__column--active': timelineHelper.isColumnActive(column, columnKey),
            'timeline-group__column--large': isLarge,
        }"
        @mouseenter="timelineHelper.setActiveColumn(column, )"
        @mouseleave="timelineHelper.setActiveColumn(null)"
    >
        <ak-skeleton-loader
            class="ak-data-table__skeleton-loader"
            :loading="loading"
        />
        <span v-if="!column.widget">{{ applyColumnFormatting(columnKey, column.value) }}</span>
        <ak-widget
            v-else
            :type="column.widget.type"
            :attributes="{...column.widget.attributes, id: column.widget.id }"
            :slot="column.widget.slot"
            :callbacks="column.widget.callbacks"
            :context-type="column.widgetContextType"
            :column-id="columnKey"
            :object-id="objectId"
            :callback-uri="widgetCallbackUri"
            :params-for-callback="paramsForCallback"
            v-on="$listeners"
        >
            {{ applyColumnFormatting(columnKey, column.value) }}
        </ak-widget>
    </div>
</template>

<script>
export default {
    props: {
        column: {
            type: Object,
            required: true,
        },
        columnKey: {
            type: String
        },
        objectId: {
            type: String|Number,
            required: true,
        },
        columns: {
            type: Object,
            required: true,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
        timelineHelper: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean
        }
    },
    computed: {
        /**
         * Check if we need to show the timeline column bigger
         * @return {false|{type: *, required: boolean}|{type: *, required: boolean}|*}
         */
        isLarge() {
            const labelColumn = this.columns.items.find(x => x.id === this.columnKey);
            return labelColumn.widget.type === 'column-label-menu' && labelColumn.widget.attributes.triggerLabelDescription;
        }
    },
    methods: {
        /**
         * Get column formatting
         * @param key
         * @param value
         * @return {*}
         */
        applyColumnFormatting(key, value) {
            const formatting = this.columns.items.find(x => x.id === key).formatting;

            // if there is no formatting set in for this value just return the value
            if(typeof formatting == 'undefined') {
                return value;
            }

            // else we apply the formatting
            return this.$applyFormatting(value, formatting);
        },
    }
}
</script>