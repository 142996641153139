var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-modal",
    {
      staticClass: "ak-export-dialog",
      attrs: { width: "600px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("h1", {
        domProps: {
          textContent: _vm._s(_vm.title ? _vm.title : _vm.$t("Export")),
        },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("ak-loader", {
            attrs: { loading: _vm.isLoading, static: true, size: "small" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("div", { staticClass: "ak-form__error" }, [
            _c("i", { staticClass: "ak-icon m-r-xs-5" }, [_vm._v("warning")]),
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.filterWidgets, function (filterWidget) {
        return _c("ak-widget", {
          attrs: {
            type: filterWidget.type,
            attributes: Object.assign(
              {},
              filterWidget.attributes,
              filterWidget.formControl,
              { id: filterWidget.id }
            ),
          },
          on: {
            input: function ($event) {
              return _vm.setFilterValue($event, filterWidget.id)
            },
            submit: _vm.doExport,
          },
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-t-15" },
        [
          _c("ak-button", { on: { click: _vm.doExport } }, [
            _vm._v(_vm._s(_vm.$t("Start export"))),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }