var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timeline-group__row" }, [
    _c(
      "div",
      {
        on: {
          mouseover: function ($event) {
            return _vm.setContextualMenuHover(true)
          },
          mouseleave: function ($event) {
            return _vm.setContextualMenuHover(false)
          },
        },
      },
      [
        _c("ak-popover-menu", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRowActive,
              expression: "isRowActive",
            },
          ],
          staticClass: "timeline-group__contextual-menu",
          attrs: { direction: "right" },
          on: { change: _vm.setContextualMenuOpen },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function (ref) {
                var open = ref.open
                var toggleMenu = ref.toggleMenu
                return [
                  _c(
                    "ak-button",
                    {
                      staticClass: "timeline-group__contextual-trigger",
                      attrs: {
                        size: "x-small",
                        active: open,
                        colorMode: "primary",
                      },
                      on: { click: toggleMenu },
                    },
                    [
                      _c(
                        "i",
                        { staticClass: "ak-icon ak-icon--large ak-icon--bolt" },
                        [
                          _vm._v(
                            "\n                        more_horiz\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "default",
              fn: function () {
                return _vm._l(_vm.row.widgets.list, function (widget) {
                  return _c(
                    "ak-widget",
                    _vm._g(
                      {
                        key: widget.id,
                        staticClass: "ma-2",
                        attrs: {
                          slot: widget.slot,
                          attributes: Object.assign({}, widget.attributes, {
                            id: widget.id,
                            showArrow: false,
                          }),
                          callbacks: widget.callbacks,
                          "context-type": _vm.row.widgets.contextType,
                          "object-id": _vm.row.id,
                          "callback-uri": _vm.widgetCallbackUri,
                          "params-for-callback": _vm.paramsForCallback,
                          type: "ak-popover-menu-item",
                        },
                        slot: widget.slot,
                      },
                      _vm.$listeners
                    )
                  )
                })
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "timeline-group__row-main",
        class: {
          "timeline-group__row-main--active": _vm.isRowActive,
          "timeline-group__row-main--dragging": _vm.row.dragging,
          "timeline-group__row-main--draft": _vm.row.isDraft,
        },
      },
      [
        _vm.row.dragging && _vm.row.originalDate
          ? _c(
              "span",
              {
                staticClass: "timeline-group__row-drag-label",
                class: {
                  "timeline-group__row-drag-label--draft": _vm.row.isDraft,
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("Slepen naar")) +
                    "\n            "
                ),
                _c("span", { staticClass: "timelinge-group__row-drag-date" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$moment(_vm.row.date).format("DD/MM/YYYY")) +
                      "\n            "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.row.isDraft
          ? _c("span", { staticClass: "timeline-group__row-draft-label" }, [
              _vm._v("\n            " + _vm._s(_vm.$t("Draft")) + "\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "timeline-header-column",
          _vm._g(
            {
              attrs: {
                column: _vm.row.headerColumn,
                "object-id": _vm.row.id,
                row: _vm.row,
                columns: _vm.columns,
                "timeline-helper": _vm.timelineHelper,
                "widget-callback-uri": _vm.widgetCallbackUri,
                "params-for-callback": _vm.paramsForCallback,
                loading: _vm.loading,
              },
              on: {
                mouseover: function ($event) {
                  return _vm.setRowHover(true)
                },
                mouseleave: function ($event) {
                  return _vm.setRowHover(false)
                },
                click: function ($event) {
                  return _vm.setRowClicked(true)
                },
                clickOutside: function ($event) {
                  return _vm.setRowClicked(false)
                },
              },
            },
            _vm.$listeners
          )
        ),
        _vm._v(" "),
        _vm._l(_vm.row.columns, function (column, columnKey) {
          return _c(
            "timeline-column",
            _vm._g(
              {
                key: columnKey,
                attrs: {
                  column: column,
                  columnKey: columnKey,
                  "object-id": _vm.row.id,
                  columns: _vm.columns,
                  "timeline-helper": _vm.timelineHelper,
                  "widget-callback-uri": _vm.widgetCallbackUri,
                  "params-for-callback": _vm.paramsForCallback,
                  loading: _vm.loading,
                },
              },
              _vm.$listeners
            )
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }