var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-click-outside",
          rawName: "v-on-click-outside",
          value: _vm.clickOutside,
          expression: "clickOutside",
        },
      ],
      staticClass: "timeline-group__column timeline-group__column--header",
      on: {
        mouseover: function ($event) {
          return _vm.$emit("mouseover")
        },
        mouseleave: function ($event) {
          return _vm.$emit("mouseleave")
        },
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("ak-skeleton-loader", {
        staticClass: "ak-data-table__skeleton-loader",
        attrs: { loading: _vm.loading },
      }),
      _vm._v(" "),
      _vm.column && !_vm.loading
        ? _c(
            "div",
            { staticClass: "timeline-group__column-header-content" },
            [
              !_vm.column.widget
                ? _c("span", [_vm._v(_vm._s(_vm.columnFormatted))])
                : _c(
                    "ak-widget",
                    _vm._g(
                      {
                        attrs: {
                          slot: _vm.column.widget.slot,
                          type: _vm.column.widget.type,
                          attributes: Object.assign(
                            {},
                            _vm.column.widget.attributes,
                            { id: _vm.column.widget.id }
                          ),
                          callbacks: _vm.column.widget.callbacks,
                          "context-type": _vm.column.widgetContextType,
                          "object-id": _vm.objectId,
                          "callback-uri": _vm.widgetCallbackUri,
                          "params-for-callback": _vm.paramsForCallback,
                        },
                        slot: _vm.column.widget.slot,
                      },
                      _vm.$listeners
                    ),
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.columnFormatted) +
                          "\n        "
                      ),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }