<template>
    <ak-form-decorator
            :id="id"
            :title="title"
            :helpText="helpText"
            :error="error"
            :hasError="hasError"
            :required="required"
            :value="value"
            :locales="locales"
    >
        <status-label
            :color="color"
            :background="background"
            size="large"
        > {{value}} </status-label>

        <slot name="popover"></slot>
    </ak-form-decorator>

</template>

<script>


export default {
    props: {
        id: {
            type: String,
            default: '',
            required: true,
        },
        title: {
            type: String,
            default: '',
            required: false,
        },
        hidden: {
            type: Boolean,
            default: false,
            required: false,
        },
        helpText: {
            type: String,
            default: '',
            required: false,
        },
        error: {
            type: String,
            default: '',
            required: false,
        },
        hasError: {
            type: Boolean,
            default: false
        },
        value: {
            type: String|Array|Number,
            required: false,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        locales: {
            type: Array,
            required: false,
            default: () => []
        },
        color: {
            type: Object,
        },
        background: {
            type: Object,
        },
    }
}
</script>
