var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "timeline-group__column",
      class: {
        "timeline-group__column--active": _vm.timelineHelper.isColumnActive(
          _vm.column,
          _vm.columnKey
        ),
        "timeline-group__column--large": _vm.isLarge,
      },
      on: {
        mouseenter: function ($event) {
          return _vm.timelineHelper.setActiveColumn(_vm.column)
        },
        mouseleave: function ($event) {
          return _vm.timelineHelper.setActiveColumn(null)
        },
      },
    },
    [
      _c("ak-skeleton-loader", {
        staticClass: "ak-data-table__skeleton-loader",
        attrs: { loading: _vm.loading },
      }),
      _vm._v(" "),
      !_vm.column.widget
        ? _c("span", [
            _vm._v(
              _vm._s(_vm.applyColumnFormatting(_vm.columnKey, _vm.column.value))
            ),
          ])
        : _c(
            "ak-widget",
            _vm._g(
              {
                attrs: {
                  slot: _vm.column.widget.slot,
                  type: _vm.column.widget.type,
                  attributes: Object.assign({}, _vm.column.widget.attributes, {
                    id: _vm.column.widget.id,
                  }),
                  callbacks: _vm.column.widget.callbacks,
                  "context-type": _vm.column.widgetContextType,
                  "column-id": _vm.columnKey,
                  "object-id": _vm.objectId,
                  "callback-uri": _vm.widgetCallbackUri,
                  "params-for-callback": _vm.paramsForCallback,
                },
                slot: _vm.column.widget.slot,
              },
              _vm.$listeners
            ),
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.applyColumnFormatting(_vm.columnKey, _vm.column.value)
                  ) +
                  "\n    "
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }