var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-form-decorator",
    {
      attrs: {
        id: _vm.id,
        title: _vm.title,
        helpText: _vm.helpText,
        error: _vm.error,
        hasError: _vm.hasError,
        required: _vm.required,
        value: _vm.value,
        locales: _vm.locales,
      },
    },
    [
      _c(
        "v-select",
        _vm._g(
          {
            class: { "ak-form__control--has-value": _vm.currentValue !== null },
            attrs: {
              options: _vm.selectOptions,
              multiple: false,
              disabled: _vm.readOnly,
              reduce: _vm.reduce,
            },
            on: {
              "option:selected": function ($event) {
                return _vm.$emit("blur")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "selected-option",
                fn: function (option) {
                  return [
                    _c("span", {
                      staticStyle: {
                        width: "15px",
                        height: "15px",
                        "border-radius": "50%",
                        "margin-right": "5px",
                      },
                      style: { background: option.value },
                    }),
                    _vm._v(" "),
                    option
                      ? _c("span", {
                          staticClass: "ak-select__option",
                          domProps: {
                            innerHTML: _vm._s(
                              typeof option.label === "object"
                                ? option.label.label
                                : option.label
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "option",
                fn: function (option) {
                  return [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c("span", {
                        staticStyle: {
                          width: "15px",
                          height: "15px",
                          display: "inline-block",
                          "border-radius": "50%",
                          "margin-right": "5px",
                        },
                        style: { background: option.value },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(option.label) },
                      }),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.currentValue,
              callback: function ($$v) {
                _vm.currentValue = $$v
              },
              expression: "currentValue",
            },
          },
          _vm.$currentListeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }