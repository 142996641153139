import App from './views/App.vue';
import router from './routes';

import AkApprovalStatusSelect from "./widgets/AkApprovalStatusSelect.vue";
import StatusLabel from "./widgets/StatusLabel.vue";
import ShowPerformanceInfo from "./widgets/ShowPerformanceInfo.vue";
import FormStatusLabel from "./widgets/FormStatusLabel.vue";
import ColumnWidthComments from "./widgets/ColumnWidthComments.vue";
import ColumnlabelMenu from "./widgets/ColumnlabelMenu.vue";
import VideoControl from "./widgets/Form/VideoControl.vue";
import ColorSelect from "./widgets/Form/ColorSelect.vue";

Vue.component('ak-approval-status-select', AkApprovalStatusSelect);
Vue.component('status-label', StatusLabel);
Vue.component('show-performance-info', ShowPerformanceInfo);
Vue.component('form-status-label', FormStatusLabel);
Vue.component('column-width-comments', ColumnWidthComments);
Vue.component('column-label-menu', ColumnlabelMenu);
Vue.component('video-control', VideoControl);
Vue.component('color-select', ColorSelect);

let app = new Vue({
        data() { 
            return {
                globalMessages: []
            }
        },
        router,
        render: h => h(App),
}).$mount('#app')

AppKit.init(app);
