export default class TimelineHelper {
    /**
     * Constructor
     */
    constructor() {
        this.activeColumnKey = null;
        this.activeRowKey = null;
        this.activeGroupId = null;
        this.scrollToGroupId = null;
        this.visibleGroupIds = [];
    }

    /**
     * Set active column key
     * @param column
     */
    setActiveColumn(column) {
        this.activeColumnKey = column ? column.value : null;
    }

    /**
     * Set active row key
     * @param row
     */
    setActiveRow(column) {
        this.activeRowKey = column ? column.id : null;
    }

    /**
     * Set active group id
     * @param groupId
     */
    setActiveGroupId(groupId) {
        this.activeGroupId = groupId;
    }

    /**
     * Set visible group ids
     * @param groupIds
     */
    setVisibleGroupIds(groupIds) {
        this.visibleGroupIds = groupIds;
    }

    /**
     * Set scroll to group id
     * @param groupId
     */
    setScrollToGroupId(groupId) {
        this.scrollToGroupId = groupId;
    }

    /**
     * Is column active
     *
     * @param column
     * @param columnKey
     * @return {boolean}
     */
    isColumnActive(column, columnKey) {
        return (this.activeColumnKey === column.value && this.activeColumnKey !== null) || this.isRowActive(columnKey);
    }

    /**
     * Is column active
     * @param key
     * @return {boolean}
     */
    isRowActive(key) {
        return this.activeRowKey === key && this.activeRowKey !== null;
    }

    /**
     * is group active
     * @return {null}
     */
    isGroupActive(groupId) {
        return this.activeGroupId === groupId;
    }

    /**
     * is group active
     * @return {null}
     */
    isGroupVisible(groupId) {
        return this.visibleGroupIds.includes(groupId);
    }

    /**
     * Get first visible group id
     * @return {*}
     */
    getFirstVisibleGroupId() {
        return this.visibleGroupIds[0];
    }

    /**
     * Get last visible group id
     * @return {*}
     */
    getLastVisibleGroupId() {
        return this.visibleGroupIds[this.visibleGroupIds.length - 1];
    }
}