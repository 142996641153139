<template>
    <div class="timeline-group" :data-group-id="groupId">
        <div class="timeline-group__header">
            <div class="timeline-group__header-info">
                <div class="timeline-group__header-title">
                    {{group.title}}
                </div>
                <div class="timeline-group__header-date-range">
                    {{group.dateRangeFormatted}}
                </div>
            </div>

            <div class="timeline-group__header-widgets ak-button-group">
                <ak-widget
                    v-for="widget in group.widgets.list"
                    :key="widget.id + group.dataObjects.length"
                    :type="widget.type"
                    :attributes="{...widget.attributes, id: widget.id }"
                    :slot="widget.slot"
                    :callbacks="widget.callbacks"
                    :context-type="group.widgets.contextType"
                    :callback-uri="widgetCallbackUri"
                    :params-for-callback="{...paramsForCallback, groupDate: group.date}"
                    v-on="$listeners"
                />
            </div>
        </div>
        <draggable
            v-model="group.dataObjects"
            class="timeline-group__rows"
            :disabled="!sortingEnabled"
            :group="{ name: 'rows', pull: 'clone', put: true }"
            :clone="cloneRow"
            :move="checkMove"
            handle=".timeline-group__column-header-content"
            drag-class="timeline-group__row--dragging"
            @choose="onChoose"
            @unchoose="onUnChoose"
            @add="onAddRow"
            @remove="onRemoveRow"
            @end="onDragEnd"
            @change="onChange"
            ref="draggable"
        >
            <timeline-row
                    v-for="(row,key) in group.dataObjects"
                    ref="rows"
                    :key="row.id + key"
                    :row="row"
                    :columns="columns"
                    :timeline-helper="timelineHelper"
                    :widget-callback-uri="widgetCallbackUri"
                    :params-for-callback="paramsForCallback"
                    :loading="loading"
                    v-on="$listeners"
            />
        </draggable>

        <timeline-sort-modal
            v-if="activeSortRequest"
            :row="activeSortRequest.row"
            :date="activeSortRequest.date"
            @cancel="cancelSortRequest"
            @confirm="confirmSortRequest"
        />
    </div>
</template>

<script>
import TimelineRow from "./TimelineRow.vue";
import TimelineSortModal from "./../Modal/TimelineSortModal.vue";

export default {
    components: {
        TimelineRow,
        TimelineSortModal
    },
    data() {
        return {
            activeSortRequest: null,
        }
    },
    props: {
        groupId: {
            type: String,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
        columns: {
            type: Object,
            required: true,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
        timelineHelper: {
            type: Object,
            required: true,
        },
        sortingEnabled: {
            type: Boolean,
        },
        grouping: {
            type: Object,
        },
        loading: {
            type: Boolean
        },
        activeObserver:{
            type: IntersectionObserver,
        }
    },
    methods: {
        /**
         * Create a new data object
         *
         * @param originalDataObject
         * @return {boolean}
         */
        cloneRow(originalDataObject) {
            const newDataObject = this.$cloneDeep(originalDataObject)
            originalDataObject.dragging = true;
            newDataObject.oldGroupId = this.groupId;
            return newDataObject;
        },
        /**
         * On add of a new row
         */
        onAddRow() {
            this.group.dataObjects.forEach(dataObject => {
                dataObject.dragging = false;
            });
        },
        /**
         * On remove of a new row
         */
        onRemoveRow() {
            this.group.dataObjects = this.group.dataObjects.filter(dataObject => !dataObject.dragging);
        },
        /**
         * On drag choosen
         */
        onChoose($event) {
            this.$nextTick(() => $event.item.classList.add('timeline-group__row--old'));
        },
        /**
         * On stop choosen
         * @param $event
         */
        onUnChoose($event) {
            $event.item.classList.remove('timeline-group__row--old')
        },
        /**
         * On drag end
         */
        onDragEnd() {
            this.group.dataObjects.forEach(dataObject => {
                dataObject.dragging = false;
            });
        },
        /**
         * Update the drag date on move
         * @param $event
         */
        checkMove($event) {
            const toGroup = $event.to.__vue__.$parent.group;
            const futureIndex = $event.draggedContext.futureIndex;

            if(! $event.draggedContext.element.originalDate) {
                $event.draggedContext.element.originalDate = $event.draggedContext.element.date;
            }

            if ($event.to === $event.from && $event.draggedContext.index === futureIndex) {
                $event.draggedContext.element.date = $event.draggedContext.element.originalDate;
                $event.draggedContext.element.originalDate = null;
            } else {
                $event.draggedContext.element.date = this.getNewDateByIndex(toGroup, futureIndex, $event.draggedContext.element);
            }
        },
        /**
         * Get new date by the index
         * @param group
         * @param newIndex
         * @param originalDate
         * @return {*}
         */
        getNewDateByIndex(group, newIndex, originalRow) {
            let newDate = this.$moment(group.date);

            if (newIndex !== 0) {
                const prevItem = group.dataObjects[newIndex - 1];
                const nextItem = group.dataObjects[newIndex]; // the next item will be the current newIndexes place

                // Check if the next and prev items are the same day only if the previeous item is not the same day as the current day
                if(nextItem && prevItem.id !== originalRow.id && this.$moment(prevItem.date).isSame(this.$moment(nextItem.date),'day')) {
                    // if so we will add this item to the same day
                    newDate = this.$moment(prevItem.date);
                } else {
                    newDate = this.$moment(prevItem.date).add('days', 1);
                }
            }

            // copy the time of the originial date
            const originalDate = this.$moment(originalRow.originalDate);
            newDate.set({
                hour:   originalDate.get('hour'),
                minute: originalDate.get('minute'),
                second: originalDate.get('second')
            })

            return newDate.toISOString();
        },
        /**
         * On change
         * @param $event
         */
        onChange($event) {
            const row = $event.added ? $event.added : $event.moved;
            this.activeSortRequest = {
                row: row.element,
                date: this.getNewDateByIndex(this.group, row.newIndex, row.element.date)
            }
        },
        /**
         * Cancel sort request
         */
        cancelSortRequest() {
            this.$emit('updateTimelineGroup', {objectId: this.activeSortRequest.row.id, oldObjectDate: this.activeSortRequest.row.date, objectDate: this.activeSortRequest.date});
            this.activeSortRequest = null;
        },
        /**
         * Confirm sort request
         * @param newDate
         */
        confirmSortRequest($event) {
            this.$emit('sort', {objectId: this.activeSortRequest.row.id, value: $event});
            this.activeSortRequest = null;
        }
    }
}
</script>