var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-form-decorator",
    {
      attrs: {
        id: _vm.id,
        title: _vm.title,
        helpText: _vm.helpText,
        error: _vm.error,
        hasError: _vm.hasError,
        required: _vm.required,
        value: _vm.value,
        locales: _vm.locales,
      },
    },
    [
      _c(
        "status-label",
        {
          attrs: {
            color: _vm.color,
            background: _vm.background,
            size: "large",
          },
        },
        [_vm._v(" " + _vm._s(_vm.value) + " ")]
      ),
      _vm._v(" "),
      _vm._t("popover"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }