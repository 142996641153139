<template>
    <div class="container">
        <ak-ui-kit/>
    </div>
</template>

<script>
export default {
    name: 'UiKit',
}
</script>
