var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-wrapper",
    {
      class: {
        "ak-wrapper--no-header":
          _vm.$authService && !_vm.$authService.isAuthenticated(),
      },
    },
    [
      _vm.$authService.isAuthenticated() && _vm.userInterface
        ? _c("ak-header", {
            attrs: { drawerPinned: _vm.drawerPinned },
            scopedSlots: _vm._u(
              [
                {
                  key: "header-left",
                  fn: function () {
                    return [
                      _c("cms-active-workspace"),
                      _vm._v(" "),
                      _vm.$getSetting("isStaging")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ak-chip ak-chip--small ak-chip--outlined ak-chip--warning",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("Testomgeving")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : !_vm.$getSetting("isLocal") &&
                          !_vm.$getSetting("isStaging")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ak-chip ak-chip--small ak-chip--outlined ak-chip--info",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("Productie")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "ak-chip ak-chip--small ak-chip--outlined ak-chip--success",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("Lokaal")) +
                                  "\n            "
                              ),
                            ]
                          ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "logo",
                  fn: function () {
                    return [_c("cms-active-workspace-logo")]
                  },
                  proxy: true,
                },
                {
                  key: "header-right",
                  fn: function () {
                    return [
                      _c("cms-settings-menu"),
                      _vm._v(" "),
                      _c("cms-account-menu"),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1108580372
            ),
            model: {
              value: _vm.showDrawer,
              callback: function ($$v) {
                _vm.showDrawer = $$v
              },
              expression: "showDrawer",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ak-drawer",
        {
          attrs: {
            logoPath: _vm.$getSetting("assets.logoDrawer"),
            showDrawer: _vm.showDrawer,
          },
          on: {
            close: function ($event) {
              _vm.showDrawer = false
            },
            togglePinned: function ($event) {
              _vm.drawerPinned = $event
            },
          },
        },
        [
          _vm.userInterface
            ? _c("ak-menu", {
                attrs: { items: _vm.userInterface.menu, searchEnabled: true },
                on: {
                  menuItemClicked: function ($event) {
                    !_vm.drawerPinned ? (_vm.showDrawer = false) : null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$authService.isAuthenticated() ||
      (_vm.$authService.isAuthenticated() && _vm.userInterface)
        ? _c(
            "div",
            {
              staticClass: "ak-content",
              class: {
                "ak-content--pinned":
                  _vm.drawerPinned &&
                  _vm.$authService &&
                  _vm.$authService.isAuthenticated(),
              },
            },
            [_c("router-view")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ak-loader", { attrs: { size: "large", loading: _vm.loading } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }