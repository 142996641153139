<template>
    <ak-popover-menu
        :open="open"
        :trigger-label="triggerLabel"
        :trigger-icon="triggerIcon"
        :direction="direction"
        :show-overflow="showOverflow"
        :items="items"
        :id="id"
        :columnId="columnId"
        :objectId="objectId"
        :callbackUri="callbackUri"
        :contextType="contextType"
        :params-for-callback="paramsForCallback"
        v-on="$listeners"
        class="column-label-menu"
    >
        <template v-slot:trigger="{open:open, toggleMenu:toggleMenu}">
            <ak-button
                    class="ak-popover-trigger column-label-menu__trigger"
                    size="small"
                    :active="open"
                    colorMode="text"
                    :disableBackground="true"
                    @click="toggleMenu"
            >
                <div class="column-label-menu__label">
                    <span v-if="triggerIcon" class="ak-icon ak-button__icon">{{ triggerIcon }}</span>
                    {{ $t(triggerLabel) }}
                    <i class="ak-icon m-l-auto">expand_more</i>
                </div>
                <div v-if="triggerLabelDescription" class="column-label-menu__label-description">
                    {{ triggerLabelDescription }}
                </div>
            </ak-button>
        </template>
    </ak-popover-menu>
</template>

<script>

export default {
    name: 'column-label-menu',
    props: {
        open: {
            type: Boolean,
            required: false,
        },
        triggerLabel: {
            type: String,
            required: false,
        },
        triggerLabelDescription: {
            type: String,
            required: false,
        },
        triggerIcon: {
            type: String,
            required: false,
        },
        direction: {
            type: String,
            default: 'left',
            required: false,
        },
        items: {
            type: Array,
            required: false,
        },
        showOverflow: {
            type: Boolean,
            default: false
        },
        id: {
            type: String | Number,
        },
        callbackUri: {
            type: String,
        },
        contextType: {
            type: String,
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
        objectId: {
            type: String,
        },
        columnId: {
            type: String,
        }
    },
}
</script>
