var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-t-xs-20 p-b-xs-20" },
    [
      _vm.order && _vm.cart && _vm.receipt
        ? _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  { staticClass: "m-b-xs-20" },
                  [
                    _c(
                      "ak-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.backToOrders },
                      },
                      [
                        _c("i", { staticClass: "ak-icon ak-button__icon" }, [
                          _vm._v("arrow_left"),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("Ga terug")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-8" }, [
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Bestelling")) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("strong", { staticClass: "m-l-auto" }, [
                          _vm._v(_vm._s(_vm.cart.code)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "ak-table ak-panel__table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "ak-table-row" }, [
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Artikel")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Aantal")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Prijs")) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        { staticClass: "ak-table-body" },
                        [
                          _vm._l(_vm.receipt.items, function (line) {
                            return _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                line.shoppable.href
                                  ? _c("a", {
                                      staticClass: "ak-link ak-link--uppercase",
                                      attrs: {
                                        href: line.shoppable.href,
                                        target: "_blank",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(line.title),
                                      },
                                    })
                                  : _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(line.title),
                                      },
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(_vm._s(line.quantity)),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(_vm._s(line.amount.formatted)),
                              ]),
                            ])
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.receipt.lines, function (line) {
                            return _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }),
                              _vm._v(" "),
                              _c("td", {
                                staticClass: "ak-table-column text--right",
                                domProps: { innerHTML: _vm._s(line.title) },
                              }),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(_vm._s(line.amount.formatted)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel m-b-xs-20  ak-panel--no-padding" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Betaling")) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.order.paymentStatus === 1
                      ? _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("tbody", { staticClass: "ak-table-body" }, [
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c("td", { staticClass: "ak-table-column" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Betaling ontvangen")) +
                                      "\n                            "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.inspector.paymentType
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.inspector.paymentType.title
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.paymentCardHolderName
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.order.paymentCardHolderName
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.getPaymentCardNumber
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.order.getPaymentCardNumber
                                            ) +
                                            "\n                                "
                                        ),
                                        _vm.order
                                          .paymentCardExpirationMonthAndYear
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$t("Vervaldatum")
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.order
                                                      .paymentCardExpirationMonthAndYear
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.paymentMessage
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.order.paymentMessage) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : !_vm.order.cancelled
                      ? _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("tbody", { staticClass: "ak-table-body" }, [
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c("td", { staticClass: "ak-table-column" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.$t("Betaling openstaande")) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.order.paymentMessage
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.order.paymentMessage) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c(
                                  "td",
                                  { staticClass: "ak-table-column" },
                                  [
                                    _c(
                                      "ak-button",
                                      { on: { click: _vm.markAsPaid } },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t("Markeren als betaald")
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.order.comments
                  ? _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Opmerkingen")) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ak-panel__body" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.order.comments) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Historiek")) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "ak-table ak-panel__table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "ak-table-row" }, [
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Datum")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Gebeurtenis")) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tbody", { staticClass: "ak-table-body" }, [
                        _vm.order.dateCancelled
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datetime")(_vm.order.dateCancelled)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.$t("Bestelling geannuleerd")) +
                                    "\n                              "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.dateOfShipping
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datetime")(_vm.order.dateOfShipping)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.$t("Bestelling verzonden")) +
                                    "\n                              "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.paymentStatus === 1 && _vm.order.dateOfPayment
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datetime")(_vm.order.dateOfPayment)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t("Betaling ontvangen via @type", {
                                        "@type":
                                          _vm.inspector.paymentType.title,
                                      })
                                    ) +
                                    "\n                              "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inspector.isOrdered
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(_vm._f("datetime")(_vm.order.date))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("Bestelling geplaatst")) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", { staticClass: "ak-table-row" }, [
                          _c("td", { staticClass: "ak-table-column" }, [
                            _vm._v(_vm._s(_vm._f("datetime")(_vm.cart.date))),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "ak-table-column" }, [
                            _vm._v(_vm._s(_vm.$t("Creatie winkelmandje"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding  m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Persoonlijke gegevens")) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ak-panel__body ak-panel__body--small reading",
                      },
                      [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.cartDetails.firstName) +
                                "  " +
                                _vm._s(_vm.cartDetails.surname)
                            ),
                          ]),
                          _c("br"),
                        ]),
                        _vm._v(" "),
                        _vm.cartDetails.streetName ||
                        _vm.cartDetails.streetNumber ||
                        _vm.cartDetails.boxNumber ||
                        _vm.cartDetails.postalCode ||
                        _vm.cartDetails.city ||
                        _vm.cartDetails.countryIso
                          ? _c("p", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.cartDetails.streetName) +
                                  " " +
                                  _vm._s(_vm.cartDetails.streetNumber) +
                                  " " +
                                  _vm._s(_vm.cartDetails.boxNumber)
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.cartDetails.postalCode) +
                                  " " +
                                  _vm._s(_vm.cartDetails.city) +
                                  " " +
                                  _vm._s(_vm.cartDetails.countryIso) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.cartDetails.phone)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:" + _vm.cartDetails.email,
                              },
                            },
                            [_vm._v(_vm._s(_vm.cartDetails.email))]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding  m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Factuuradres")) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ak-panel__body ak-panel__body--small reading",
                      },
                      [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.order.invoiceFirstName) +
                                "  " +
                                _vm._s(_vm.order.invoiceSurnames)
                            ),
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _vm.order.invoiceCompanyName
                            ? _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.order.invoiceCompanyName) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.order.invoiceVat
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.order.invoiceVat)),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.order.invoiceStreetAddress ||
                        _vm.order.invoiceStreetNumber ||
                        _vm.order.invoiceBoxNumber ||
                        _vm.order.invoicePostalCode ||
                        _vm.order.invoiceCity ||
                        _vm.order.invoiceCountryIso
                          ? _c("p", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.order.invoiceStreetAddress) +
                                  " " +
                                  _vm._s(_vm.order.invoiceStreetNumber) +
                                  " " +
                                  _vm._s(_vm.order.invoiceBoxNumber)
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.order.invoicePostalCode) +
                                  " " +
                                  _vm._s(_vm.order.invoiceCity) +
                                  " " +
                                  _vm._s(_vm.order.invoiceCountryIso) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.order.phone)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            { attrs: { href: "mailto:" + _vm.order.email } },
                            [_vm._v(_vm._s(_vm.order.email))]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Leveringsgegevens")) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("strong", { staticClass: "m-l-auto" }, [
                          _vm.order.shippingTypeId === 1
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("Afhalen")) +
                                    "\n                                "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("Leveren")) +
                                    "\n                                "
                                ),
                              ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "ak-table ak-panel__table" }, [
                      _c("tbody", { staticClass: "ak-table-body" }, [
                        _vm.order.shippingFirstName
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Voornaam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.order.shippingFirstName) +
                                    "\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingSurnames
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Achternaam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.order.shippingSurnames) +
                                    "\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingReceiverName
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Ontvanger naam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.order.shippingReceiverName) +
                                    "\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingCompanyName
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Bedrijfsnaam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.order.shippingCompanyName) +
                                    "\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingStreetAddress ||
                        _vm.order.shippingStreetNumber ||
                        _vm.order.shippingBoxNumber ||
                        _vm.order.shippingPostalCode ||
                        _vm.order.shippingCity ||
                        _vm.order.shippingCountryIso
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Adres:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.order.shippingStreetAddress) +
                                    " " +
                                    _vm._s(_vm.order.shippingStreetNumber) +
                                    " " +
                                    _vm._s(_vm.order.shippingBoxNumber) +
                                    ",\n                                " +
                                    _vm._s(_vm.order.shippingPostalCode) +
                                    " " +
                                    _vm._s(_vm.order.shippingCity) +
                                    " " +
                                    _vm._s(_vm.order.shippingCountryIso) +
                                    "\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.requestedDeliveryDate
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Gewenste leveringsdatum:"))
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm
                                        .$moment(
                                          _vm.order.requestedDeliveryDate
                                        )
                                        .format("DD-MM-YYYY")
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        attrs: { name: "childview" },
        on: { paymentSaved: _vm.paymentSaved },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }