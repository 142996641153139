<template>
    <ak-form-decorator
            :id="id"
            :title="title"
            :helpText="helpText"
            :error="error"
            :hasError="hasError"
            :required="required"
            :value="value"
            :locales="locales"
    >

        <div :class="{'ak-form__fieldset': ! hideFieldset}">
            <ak-widget
                v-for="control in currentControls"
                :key="id + control.id"
                v-model="currentValue[control.id]"
                :type="control.type"
                :attributes="widgetAttributes(control)"
                :callbacks="control.callbacks"
                :slot="control.slot"
                @input="setCurrentValue($event, control.id)"
                @change="$emit('change')"
            />
        </div>
    </ak-form-decorator>

</template>

<script>
import FormElementMixin from "../../../../../public/spine/resources/spine-appkit-vue/src/components/form/mixins/FormElementMixin";
import FormTrigger
    from "../../../../../public/spine/resources/spine-appkit-vue/src/components/form/triggers/FormTrigger";

// CONST
const TYPE_LINK = 'link';
const TYPE_UPLOAD = 'upload';

export default {
    name: 'video-control',
    mixins: [FormElementMixin],
    props: {
        controls: {
            type: Array,
            default: () => [],
        },
        controlErrors: {
            type: Array|Object,
        },
        hideFieldset: {
            type: Boolean,
        },
        relationString: {
            type: String,
            required: false
        },
        currentObjectId: {
            type: String,
            required: false
        }
    },
    watch: {
        /**
         * Update the currentValue when the active controls change
         */
        controls() {
            const values = {};
            this.controls.forEach(control => {
                values[control.id] = control.formControl.value;
            });

            this.currentValue = values;
        }
    },
    computed: {
        /**
         * Get current video type
         * @return {*}
         */
        videoType() {
            return this.currentValue['type'];
        },
        /**
         * Get current controls
         * @return {*[]}
         */
        currentControls() {
            return this.controls.filter(control => {
                return control.id === 'type' || this.videoType === control.attributes.bundleId;
            });
        }
    },
    methods: {
        /**
         * Retrieves the attributes of a widget.
         *
         * @param {Object} widget - The widget object.
         * @returns {Object} - The attributes of the widget.
         */
        widgetAttributes(widget) {
            let attributes = {
                id: widget.id,
                ...widget.formControl,
                ...widget.attributes,
                relation: widget.relation,
                error: null,
                hasError: false,
                controlErrors: [], // we need to reset the controlErrors ass wel in case we have an inputGroup as a child widget
                value: this.currentValue[widget.id],
                relationString: this.relationString,
                currentObjectId: 'new' // we force the current object id to new because otherwise the select-ids call of relation widget won't work.
            };

            if (! this.controlErrors) {
                return attributes;
            }

            if (this.controlErrors[widget.id]) {
                attributes = {...attributes, ...this.controlErrors[widget.id]};
                return attributes;
            }

            Object.keys(widget).forEach(property => {
                // add other props from the widgets
                if(! ['formControl', 'attributes','slot'].includes(property) && widget[property] !== null) {
                    attributes[property] = widget[property];
                }
            });

            return attributes;
        },
        /**
         * Validate the child widgets
         * @param $event
         * @param widgetId
         * @param rowId
         * @param groupId
         */
        validateChildWidget($event, widgetId) {
            this.$emit('blur', new FormTrigger(widgetId));
        },
        /**
         * Set current value
         *
         * We use this method to set the current value every time a child widget value changes
         * This is needed because vue reactivty won't work on the currentValue object because the object doesn't always
         * contain the child widget ids from the start. This causes vue reactivity to break
         *
         * @param value
         * @param id
         */
        setCurrentValue(value, id) {
            // We need to clone the current values
            const values = {...this.currentValue};
            values[id] = value;

            // We set the current value object completely for vue reactivity to work.
            this.currentValue = values;
        }
    },
    created() {
        if(!this.currentValue || !Object.values(this.currentValue).length) {
            this.currentValue = {};
            this.controls.forEach(control => {
                this.currentValue[control.id] = control.formControl.value;
            })
        }
    }
}
</script>
