var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "groups",
      staticClass: "timeline__groups",
      on: { scroll: _vm.checkScrollActions },
    },
    [
      _c("div", { ref: "loadPrev", staticClass: "timeline__groups-load-prev" }),
      _vm._v(" "),
      _vm._l(_vm.groups, function (group, key) {
        return _c(
          "timeline-group",
          _vm._g(
            {
              key: key,
              ref: "group",
              refInFor: true,
              attrs: {
                groupId: group.id,
                group: group,
                columns: _vm.columns,
                "timeline-helper": _vm.timelineHelper,
                "widget-callback-uri": _vm.widgetCallbackUri,
                "params-for-callback": _vm.paramsForCallback,
                "sorting-enabled": _vm.sortingEnabled,
                grouping: _vm.grouping,
                loading: _vm.loading,
              },
            },
            _vm.$listeners
          )
        )
      }),
      _vm._v(" "),
      _c("div", { ref: "loadNext", staticClass: "timeline__groups-load-next" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }