var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-modal",
    {
      attrs: { active: true },
      on: { close: _vm.cancel },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("ak-button", { on: { click: _vm.confirm } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("Nu verplaatsen")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ak-button",
                {
                  attrs: { disableBackground: true },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("Annuleren")) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("h1", [
        _vm._v(
          _vm._s(_vm.$t("@title verschuiven?", { "@title": "Cast planning" }))
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.$t(
                "Je hebt deze planning naar @date gesleept. Klopt dat niet, dan kan je hier alsnog de juiste datum selecteren. Je kan ook ineens het tijdstip van de @title aanpassen, indien gewenst.",
                { "@date": _vm.dateFormatted, "@title": "Cast planning" }
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "m-b-0" }),
      _vm._v(" "),
      _c("ak-date-time-picker", {
        attrs: {
          id: "date",
          title: _vm.$t("Nieuwe datum en tijdstip"),
          "help-text": _vm.$t(
            "Kies je alsnog een ander datum, dan zal deze @title naar die datum verschoven worden i. p.v. naar de datum die hier gesuggereerd wordt.",
            { "@title": "Cast planning" }
          ),
        },
        model: {
          value: _vm.localDate,
          callback: function ($$v) {
            _vm.localDate = $$v
          },
          expression: "localDate",
        },
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "m-t-0" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }