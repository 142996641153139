<template>
    <div class="timeline-group__column timeline-group__column--header"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
        @click.prevent.stop="$emit('click')"
        v-on-click-outside="clickOutside"
    >
        <ak-skeleton-loader
                class="ak-data-table__skeleton-loader"
                :loading="loading"
        />
        <div v-if="column && !loading" class="timeline-group__column-header-content">
            <span v-if="!column.widget">{{ columnFormatted }}</span>
            <ak-widget
                    v-else
                    :type="column.widget.type"
                    :attributes="{...column.widget.attributes, id: column.widget.id }"
                    :slot="column.widget.slot"
                    :callbacks="column.widget.callbacks"
                    :context-type="column.widgetContextType"
                    :object-id="objectId"
                    :callback-uri="widgetCallbackUri"
                    :params-for-callback="paramsForCallback"
                    v-on="$listeners"
            >
                {{ columnFormatted }}
            </ak-widget>
        </div>
    </div>
</template>

<script>
import TimelineColumn from "./TimelineColumn.vue";

export default {
    extends: TimelineColumn,
    props: {
        column: {
            type: Object,
        },
        row: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean
        }
    },
    computed: {
        /**
         * Get column formatting
         * @param key
         * @param value
         * @return {*}
         */
        columnFormatted() {
            const formatting = this.columns.headerColumn.formatting;

            // if there is no formatting set in for this value just return the value
            if(typeof formatting == 'undefined') {
                return this.column.value;
            }

            // else we apply the formatting
            return this.$applyFormatting(this.column.value, formatting);
        }
    },
    methods: {
        clickOutside() {
            this.$emit('clickOutside');
        }
    }
}
</script>