const formElementMixin = {
	data() {
		return {
			removeFromListener:  ['input','updateState'],
			isFocused:  null,
			localValue:  null,
			$currentListeners: {}
		}
	},
	props: {
		id: {
			type: String,
			default: '',
			required: true,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		hidden: {
			type: Boolean,
			default: false,
			required: false,
		},
		helpText: {
			type: String,
			default: '',
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		hasError: {
			type: Boolean,
			default: false
		},
		value: {
			type: String|Array|Number,
			required: false,
			default: null,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		isEmpty: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			required: false,
        },
		locales: {
            type: Array,
            required: false,
            default: () => []
        },
		isLocalized: {
			type: Boolean,
			default: false,
		},
		errorLocales: {
			type: Array,
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	watch: {
		value(newValue, oldValue) {
			if(newValue !== oldValue) {
				this.localValue = newValue;
			}
		}
	},
	computed: {
		currentValue: {
			get() {
				if (this.isLocalized) {
					return this.localValue[this.$localeHandler.getActiveLocale()];
				}

				return this.localValue;
			},
            set(e) {
				if (this.isLocalized) {
					this.localValue[this.$localeHandler.getActiveLocale()] = e;
				} else {
					this.localValue = e;
				}

				this.$emit('input', this.localValue);
				this.$emit('updateState', this.formatStateOutput(this.localValue));
			}
		}
	},
	methods: {
		// add a method where we can overwrite the output for state handling
		// see an example in AkRelation
		formatStateOutput(e) {
			return e;
		},
		focus() {
			this.isFocused = true;
			this.$emit('focus');
		},
		blur() {
			this.isFocused = false;
			this.$emit('blur');
		}
	},
	created() {
		this.localValue = this.value;
		// clone
		let tempListeners = {...this.$listeners};

		// remove the listeners that we emit self
		this.removeFromListener.forEach(listerner => {
			delete tempListeners[listerner];
		})

		this.$currentListeners = tempListeners;
	}
}

export default formElementMixin;