<template>
    <div class="timeline__groups" ref="groups" @scroll="checkScrollActions">
        <div class="timeline__groups-load-prev" ref="loadPrev"></div>
        <timeline-group
            v-for="(group,key) in groups"
            ref="group"
            :key="key"
            :groupId="group.id"
            :group="group"
            :columns="columns"
            :timeline-helper="timelineHelper"
            :widget-callback-uri="widgetCallbackUri"
            :params-for-callback="paramsForCallback"
            :sorting-enabled="sortingEnabled"
            :grouping="grouping"
            :loading="loading"
            v-on="$listeners"
        />
        <div class="timeline__groups-load-next" ref="loadNext"></div>
    </div>
</template>

<script>
import TimelineGroup from "./TimelineGroup.vue";

export default {
    components: {TimelineGroup},
    data() {
        return {
            activeObserver: null,
            loadMoreObserver: null,
            observersInitialized: false
        }
    },
    props: {
        groups: {
            type: Object|Array,
            required: true,
        },
        columns: {
            type: Object,
            required: true,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
        timelineHelper: {
            type: Object,
            required: true,
        },
        grouping: {
            type: Object,
        },
        sortingEnabled: {
            type: Boolean,
        },
        loading: {
            type: Boolean
        }
    },
    computed: {
        /**
         * The offset left for the sidebar
         * @return {string}
         */
        groupsOffsetLeft() {
            return parseInt(window.getComputedStyle(this.$refs.groups).getPropertyValue('padding-left').replace('px', ''));
        }
    },
    watch: {
        'timelineHelper.scrollToGroupId': function(newVal, oldVal) {
            if (this.timelineHelper.scrollToGroupId) {
                this.scrollToGroup(this.timelineHelper.scrollToGroupId);
            }
        }
    },
    methods: {
        /**
         * Init the intersectionObservers for the active and visible states of the groups
         */
        createLoadObservers() {
            // Observe when we need to load next items
            this.loadNextObserver = new IntersectionObserver(this.checkLoadNext, {
                root: this.$refs.groups,
                rootMargin: '0px 300px 0px -300px',
                threshold: 1.0
            });

            this.loadNextObserver.observe(this.$refs.loadNext);

            // Observe when we need to load previous items
            this.loadPrevObserver = new IntersectionObserver(this.checkLoadPrev, {
                root: this.$refs.groups,
                rootMargin: '0px 300px 0px 300px',
                threshold: 1.0
            });

            this.loadPrevObserver.observe(this.$refs.loadPrev);
        },
        /**
         * Check scroll actions
         */
        checkScrollActions() {
            this.checkGroupsVisibility();
            this.checkGroupsActive();
        },
        /**
         * Check wich groups are visible
         * @param entries
         */
        checkGroupsVisibility() {
            const visibleGroupIds= []

            this.$refs.group.forEach(group => {
                if (this.isVisible(group.$el, this.$refs.groups)) {
                    visibleGroupIds.push(group.$el.dataset.groupId);
                }
            });

            this.timelineHelper.setVisibleGroupIds(visibleGroupIds);
        },
        /**
         * Check which group is active
         * By checking which group is in a window of 100 px of the left sidebar
         */
        checkGroupsActive() {
            this.$refs.group.forEach(group => {
                if (this.isActive(group.$el, this.$refs.groups)) {
                    this.timelineHelper.setActiveGroupId(group.$el.dataset.groupId);
                    return;
                }
            });
        },
        /**
         * Is group visible in window
         *
         * @param $group
         * @return {boolean}
         */
        isVisible($group) {
            const groupRect = $group.getBoundingClientRect();
            const groupsRect = this.$refs.groups.getBoundingClientRect();
            const groupLeftX = groupRect.x - groupsRect.x;
            const groupRightX = groupRect.x - groupsRect.x + groupRect.width;
            return (groupLeftX >= this.groupsOffsetLeft && groupLeftX <= groupsRect.width) ||
                    (groupRightX >= this.groupsOffsetLeft && groupRightX <= groupsRect.width);
        },
        /**
         * Is active
         * Check if this group is in a window of 100px of the left sidebar
         * @param $group
         * @return {boolean}
         */
        isActive($group) {
            const groupRect = $group.getBoundingClientRect();
            const groupsRect = this.$refs.groups.getBoundingClientRect();
            const groupLeftX = groupRect.x - groupsRect.x;
            const groupRightX = groupRect.x - groupsRect.x + groupRect.width;
            const activeWindowLeft = this.groupsOffsetLeft + 1; // add an extra pixel for the left offset because scrollTo sometimes isent as accurate
            const activeWindowRight = this.groupsOffsetLeft + 100;

            return (groupLeftX <= activeWindowLeft && groupRightX >= activeWindowRight);
        },
        scrollToGroup(groupId) {
            const group = this.$refs.group.find(group => group.$el.dataset.groupId === groupId);

            if (! group) {
                this.$emit('loadGroup', groupId);

                // reset the scroll to group id after we have scrolled to it
                this.timelineHelper.setScrollToGroupId(null);
                return;
            }

            this.$refs.groups.scroll({
                left: group.$el.offsetLeft - this.groupsOffsetLeft,
                behavior: "smooth",
            });

            // reset the scroll to group id after we have scrolled to it
            this.timelineHelper.setScrollToGroupId(null);
        },
        /**
         * Check load next
         * @param entries
         */
        checkLoadNext(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.loading) {
                    this.$emit('loadNext');
                }
            });
        },
        /**
         * Check load prev
         * @param entries
         */
        checkLoadPrev(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.loading) {
                    this.$emit('loadPrev');
                }
            });
        }
    },
    mounted() {
        this.createLoadObservers();
    },
    updated() {
        this.checkScrollActions();
    },
    beforeDestroy() {
        this.activeObserver.disconnect();
        this.loadMoreObserver.disconnect();
    }
}
</script>