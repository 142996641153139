var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "status-label",
      class: {
        "status-label--large": _vm.size === "large",
      },
      style: {
        "--status-label-background": this.background
          ? this.background.hexCode
          : null,
        "--status-label-color": this.color ? this.color.hexCode : null,
      },
    },
    [
      _c(
        "div",
        { staticClass: "column-width-comments__tooltip" },
        [
          _vm.comments
            ? _c("ak-tooltip", { attrs: { body: _vm.comments } }, [
                _c("div", {
                  staticClass: "column-width-comments__tooltip-trigger",
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
      !_vm.label ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }